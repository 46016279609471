import React, { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { TextField, Box, Select, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../../styles/SupportUser.css";
import { SupportService } from "../../services/SupportService";
import { useNavigate } from "react-router-dom";

function SaveSupportHome() {
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: "white",
            "&:before": {
              borderColor: "white !important",
            },
            "&:after": {
              borderColor: "white !important",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              color: "white",
            },
            "&: focus": {
              color: "white",
            },
            "&:focus:not(.Mui-disabled, .Mui-error):before": {
              color: "white",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#e6f1f7",
            "--TextField-brandBorderHoverColor": "#e6f1f7",
            "--TextField-brandBorderFocusedColor": "#e6f1f7",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
            "&::placeholder": {
              color: "white",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          backgroundColor: "white",
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
          input: {
            "&::placeholder": {
              color: "white",
            },
            color: "white",
          },
        },
      },
      MuiDesktopDatePicker: {
        styleOverrides: {
          root: {
            "& input": {
              color: "white",
            },
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        },
      },
    },
  });

  const token = sessionStorage.getItem("token");
  const { decodeToken, isExpired } = useJwt("token");
  console.log(decodeToken);
  const navigate = useNavigate();

  const [support, setSupport] = useState({
    title: "",
    date: dayjs().format("YYYY-MM-DD"),
    description: "",
    status: "NUEVO",
    email: "",
  });

  const supportService = new SupportService();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupport((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(support.date);
    const response = await supportService.saveHome(support);
    console.log(response);
    navigate("/");
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="support-container">
        <Container maxWidth="sm">
          <Box
            className="box-support-home"
            sx={{
              bgcolor: "rgba(255, 255, 255, 0.2)",
              height: "90%",
              width: "40%",
            }}
          >
            <h2 className="titleHome">Contactanos</h2>
            <ThemeProvider theme={theme}>
              <div id="titleSupportHomeLabel">
                <label>Nombre de la solicitud</label>
              </div>
              <Select
                defaultValue="Lunes"
                className="titleSupportHome"
                name="title"
                onChange={handleChange}
              >
                <MenuItem value="SOLINFO">Solicitud de Información</MenuItem>
                <MenuItem value="PREFREC">Preguntas Frecuentes</MenuItem>
              </Select>
              <div id="descriptionSupportHomeLabel">
                <label>Descripción</label>
              </div>
              <TextField
                id="standard-multiline-static"
                className="descriptionSupportHome"
                label=""
                name="description"
                multiline
                rows={4}
                variant="outlined"
                onChange={handleChange}
              />
              <div id="emailHomeLabel">
                <label>Correo</label>
              </div>
              <TextField
                id="standard-multiline-static"
                className="emailHome"
                label=""
                name="email"
                multiline
                rows={1}
                variant="outlined"
                onChange={handleChange}
              />
            </ThemeProvider>
          </Box>
          <Button id="saveSupportHome" type="submit" variant="contained">
            Guardar
          </Button>
        </Container>
      </div>
    </form>
  );
}

export default SaveSupportHome;

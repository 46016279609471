import axios from "axios";

const sessionToken = sessionStorage.getItem('token')
var parseToken = '';
var token = '';

if (sessionToken){
    parseToken = JSON.parse(sessionToken)
    token = parseToken.token;
    console.log(token)
}

export class SupportService{

    save(support){
        //return axios.post("http://localhost:8080/petition/"+"save",support,{
        return axios.post("http://192.168.49.2:30713/petition/"+"save",support,{
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res => res.data)
    }

    saveHome(support){
        //return axios.post("http://localhost:8080/petition-home/"+"contact-home",support,{
        return axios.post("http://192.168.49.2:30713/petition-home/"+"contact-home",support,{
            headers:{
                'Content-Type':'application/json',
            }
        }).then(res => res.data)
    }

}
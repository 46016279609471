import axios from 'axios';

const sessionToken = sessionStorage.getItem('token')
var parseToken = '';
var token = '';
    
if (sessionToken){
    parseToken = JSON.parse(sessionToken)
    token = parseToken.token;
    console.log(token)
}

export class ProfileService{

    save(profile){
        //axios.post("http://localhost:8080/profile/"+"save",profile,{
        axios.post("http://192.168.49.2:30713/profile/"+"save",profile,{
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    update(profile){
        //return axios.post("http://localhost:8080/profile/update",profile,{
        return axios.post("http://192.168.49.2:30713/profile/update",profile,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    list(){
        //axios.get("http://localhost:8080/profile/"+"list",{
        axios.get("http://192.168.49.2:30713/profile/"+"list",{
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    listByEmail(email){
        //return axios.get("http://localhost:8080/profile/listEmail",{
        return axios.get("http://192.168.49.2:30713/profile/listEmail",{
            params:{email},
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }
}
import { React, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { isExpired, decodeToken } from "react-jwt";
import "../styles/navbar.css";

const Navbar = () => {
  const token = sessionStorage.getItem("token");
  const [showDropdown, setShowDropdown] = useState(false);
  var parseToken = "";
  var tokenSession = "";

  if (token) {
    parseToken = JSON.parse(token);
    tokenSession = parseToken.token;
  }

  const decode = decodeToken(tokenSession);
  const bandera = useState(true);

  const handleLogout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("token");
    window.location.replace("");
  };

  console.log(token);
  return (
    <div className="nav">
      <Link className="image">
        <img src="./Logo_Auxi.jpeg" width="200" />
      </Link>
      <nav className="nav-bar">
        <ul className="list">
          {token ? (
            // El token está presente, no mostrar el botón de inicio de sesión ni el enlace de registro
            <>
              <li>
                {decode.role === "professional" && (
                  <Link to="/history_offer">Inicio</Link>
                )}
              </li>
              <li>
                {decode.role === "client" && (
                  <Link to="/home">Inicio</Link>
                )}
              </li>
              <li>
                <Link to="/save_support">Contacto</Link>
              </li>
              <li>
                {decode.role === "professional" && (
                  <span className="dropdown">
                    <a href="#" onClick={() => setShowDropdown(!showDropdown)}>
                      Ofertas
                    </a>
                    {showDropdown && (
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/save_offer">Crear Oferta</Link>
                        </li>
                        <li>
                          <Link to="/history_offer">Ver Ofertas</Link>
                        </li>
                      </ul>
                    )}
                  </span>
                )}
              </li>
              <li>
                {decode.role === "client" && (
                  <span className="dropdown">
                    <a href="#" onClick={() => setShowDropdown(!showDropdown)}>
                      Ofertas
                    </a>
                    {showDropdown && (
                      <ul className="dropdown-menu-user">
                        <li>
                          <Link to="/history_offer">Ver Ofertas</Link>
                        </li>
                      </ul>
                    )}
                  </span>
                )}
              </li>
              <li>
                <span className="dropdown">
                  <a href="#" onClick={() => setShowDropdown(!showDropdown)}>
                    Perfil
                  </a>
                  {showDropdown && (
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          to={
                            decode.role === "client"
                              ? "/view_profile_user"
                              : "/view_profile"
                          }
                        >
                          Ver Perfil
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            decode.role === "client"
                              ? "/update_profile_user"
                              : "/update_profile"
                          }
                        >
                          Editar Perfil
                        </Link>
                      </li>
                    </ul>
                  )}
                </span>

                {/* <ul className="dropdown-menu">
                                <li><Link to="/save_profile"></Link></li>
                            </ul> */}
              </li>
              <li>
                <Link to="/login">
                  <button onClick={handleLogout} className="buttonLogin">
                    Cerrar Sesión
                  </button>
                </Link>
              </li>
            </>
          ) : (
            // El token no está presente, mostrar el botón de inicio de sesión y el enlace de registro

            <>
              <li>
                <Link to="/">Inicio</Link>
              </li>
              <li>
                <Link to="/contact">Contactanos</Link>
              </li>
              <li>
                <Link to="/login">
                  <button className="buttonLogin">Inicio Sesión</button>
                </Link>
              </li>

              <li>
                <Link to="/register">Registrarse</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;

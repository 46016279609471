import React, { useEffect, useState, useRef } from "react";
import { useJwt, decodeToken } from "react-jwt";
import { OfferService } from "../../services/OfferService";
import Card from "@mui/material/Card";
import "../../styles/HistoryOffer.css";
import { CardContent } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { useNavigate } from 'react-router-dom';

const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("email");

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeader": {
    fontWeight: "bold",
  },
}));

function HistoryOffer() {
  const offerService = new OfferService();
  const [selectedCustomers, setSelectedCustomers] = useState(null);

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);

  var parseToken = "";
  var tokenSession = "";

  if (token) {
    parseToken = JSON.parse(token);
    tokenSession = parseToken.token;
  }

  const decode = decodeToken(tokenSession);
  console.log("DECODE SESSION", decode);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if(decode === null){
        navigate("/")
      }else if (decode.role === "professional") {
        const findByEmail = await Load();
        setOffers(findByEmail);
        setLoading(false);
      } else {
        const findByEmailUser = await LoadUser();
        setOffers(findByEmailUser);
        setLoading(false);
      }
    })();
  }, []);

  async function Load() {
    const responseFindByEmail = await offerService.findByEmail(
      encodeURIComponent(JSON.parse(email))
    );
    console.log(responseFindByEmail);
    return responseFindByEmail;
  }

  async function LoadUser() {
    const responseFindByEmailUser = await offerService.listByEmailUser(
      encodeURIComponent(JSON.parse(email))
    );
    return responseFindByEmailUser;
  }

  const cancelOffer = (id) => {
    const formDataOffer = new FormData();
    formDataOffer.append("id", id);
    formDataOffer.append("email", JSON.parse(email));
    const responseCancelOffer = offerService.cancelOffer(formDataOffer);
    console.log(responseCancelOffer);
    window.location.reload();
  };
  const successOffer = (id) => {
    const formDataOffer = new FormData();
    formDataOffer.append("id", id);
    formDataOffer.append("email", JSON.parse(email));
    const responseSuccessOffer = offerService.successOffer(formDataOffer);
    console.log(responseSuccessOffer);
    window.location.reload();
  };

  //NUEVO->Cuando se crea la oferta
  //AGENDADO->Cuando se hace el agendamiento
  //CANCELADO-> Cuando el usuario cancela la oferta, y queda como cancelado
  //FINALIZADO-> Cuando el auxiliar termina el servicio
  const columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "name", headerName: "NOMBRE", width: 120 },
    { field: "description", headerName: "DESCRIPCIÓN", width: 150 },
    { field: "date", headerName: "FECHA", with: 120 },
    { field: "startTime", headerName: "INICIO SERVICIO", width: 120 },
    { field: "finalDate", headerName: "FINAL SERVICIO", width: 120 },
    {
      field: "state",
      headerName: "ESTADO",
      width: 120,
      renderCell: (params) => {
        if (params.value === "NUEVO") {
          return <Chip label={params.value} color="primary" />;
        } else if (params.value === "AGENDADO") {
          return (
            <Chip
              label={params.value}
              color="primary"
              style={{ backgroundColor: "#FFE135" }}
            />
          );
        } else if (params.value === "CANCELADO") {
          return (
            <Chip
              label={params.value}
              color="primary"
              style={{ backgroundColor: "#CB3234" }}
            />
          );
        } else if (params.value === "INCUMPLIDA") {
          return (
            <Chip
              label={params.value}
              color="primary"
              style={{ backgroundColor: "#B81414" }}
            />
          );
        } else if (params.value === "FINALIZADO") {
          return (
            <Chip
              label={params.value}
              color="primary"
              style={{ backgroundColor: "#008F39" }}
            />
          );
        }
      },
    },
    { field: "email", headerName: "CORREO", width: 180 },
    { field: "price", headerName: "VALOR TARIFA", width: 120 },
    {
      field: "option",
      headerName: "OPCIONES",
      with: 120,
      renderCell: (params) => {
        return (
          <Button
            disabled={
              params.row.state === "CANCELADO" ||
              params.row.state === "FINALIZADO" ||
              params.row.state === "INCUMPLIDA"
            }
            onClick={() => cancelOffer(params.row.id)}
            color="error"
          >
            Cancelar
          </Button>
        );
      },
    },
    {
      field: "option-professional",
      headerName: "",
      with: 160,
      renderCell: (params) => {
        if (decode.role === "professional") {
          return (
            <Button
              disabled={
                params.row.state === "CANCELADO" ||
                params.row.state === "FINALIZADO" ||
                params.row.state === "INCUMPLIDA"
              }
              onClick={() => successOffer(params.row.id)}
              color="success"
            >
              Finalizado
            </Button>
          );
        }
      },
    },
  ];
  console.log("OFFERS", offers);
  const rows = offers.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
    date: item.date.substring(0, 10),
    startTime: item.startTime,
    finalDate: item.finalDate,
    state: item.state,
    email: item.email,
    price: "$" + item.price,
  }));
  // const dynamiColumns = columns.map((col,i) =>{
  //     return <Column key={col.field} field={col.field} header={col.header} style={{ flex: '1 1 auto', width: '10rem' }}/>
  // })

  return (
    <form>
      <div className="history-offer-container">
        <div id="offerListLabel">
          <label>Ofertas</label>
        </div>
        <div className="card" style={{ height: 400, width: "90%" }}>
          {console.log(offers[0])}
          <StripedDataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            pagination
          />
          {/* <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              autoHeight
            /> */}
          {/* <DataTable
              className="custom-table"
              value={offers}
              sortable
              loading={loading}
              paginator
              rows={10}
              selection={selectedCustomers}
              onSelectionChange={(e) => setSelectedCustomers(e.value)}
              emptyMessage="No customers found."
              rowHover
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            >
              {dynamiColumns}
            </DataTable> */}
        </div>
      </div>
    </form>
  );
}

export default HistoryOffer;

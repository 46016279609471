import React, { useEffect, useState } from "react";
import { ProfileUserService } from "../../../services/ProfileUserService";
import { useJwt } from "react-jwt";
import "../../../styles/ViewProfileUser.css";
import { CssBaseline, Box, Badge, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";

const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("email");
var parseToken = "";
var tokenSession = "";

if (token) {
  parseToken = JSON.parse(token);
  tokenSession = parseToken.token;
}

function ViewProfileUser() {
  const { decodeToken, isExpired } = useJwt(token);
  const [profileUser, setProfileUser] = useState([]);
  const profileUserService = new ProfileUserService();
  const navigate = useNavigate();

  useEffect(() => {
    const ListProfileUser = async () => {
      const profileByEmail = await CheckoutProfile();
      setProfileUser(profileByEmail);
    };

    ListProfileUser();
  },[]);

  async function CheckoutProfile() {
    const profileByEmail = await profileUserService.listByEmail(
      JSON.parse(email)
    );
    return profileByEmail;
  }

  const handleGoBack = () => {
    navigate("/home");
  };

  function getFirstWord(nombre){
    if(!nombre){
      return ''
    }
    const first = nombre.split(' ');
    return first[0]
  }

  return (
    <div className="view-profile-user-container">
    {!profileUser && navigate("/save_profile_user")}
      <CssBaseline />
      <Box
        className="box-view-profile-user"
        height={300}
        width={1200}
        sx={{
          bgcolor: "rgba(255, 255, 255, 0.2)",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Avatar
          label={getFirstWord(profileUser.name)}
          size="xlarge"
          className="p-overlay-badge-view"
          style={{ backgroundColor: "#4caf4f", color: "#ffffff" }}
        >
          <Badge value="4" />
        </Avatar>
        <h2 className="profile-view-user">Perfil</h2>

        <div id="labelNameViewProfileUser">
          <label>Nombre</label>
        </div>
        <div className="nameViewProfileUser">
          <label>{profileUser.name}</label>
        </div>

        <div id="labelPhoneViewProfileUser">
          <label>Telefono</label>
        </div>
        <div className="phoneViewProfileUser">
            <label>{profileUser.phone}</label>
        </div>

        <div id="labelAddressViewProfileUser">
            <label>Dirección</label>
        </div>
        <div className="addressViewProfileUser">
            <label>{profileUser.address}</label>
        </div>

        <div id="labelRHViewProfileUser">
            <label>RH</label>
        </div>
        <div className="RHViewProfileUser">
            <label>{profileUser.rh}</label>
        </div>

        <div id="labelEmailViewProfileUser">
            <label>Email</label>
        </div>
        <div className="emailViewProfileUser">
            <label>{profileUser.email}</label>
        </div>
      </Box>
      <Button className="submitViewProfileUser" type="submit" variant="contained" onClick={handleGoBack}>
        Volver
      </Button>
    </div>
  );
}

export default ViewProfileUser;

import axios from 'axios';

const sessionToken = sessionStorage.getItem('token');
var parseToken = '';
var token = '';

if (sessionToken){
    parseToken = JSON.parse(sessionToken)
    token = parseToken.token;
    console.log(token)
}

export class StudiesService{

    save(studies){
        //return axios.post("http://localhost:8080/studies/"+"save",studies, {
        return axios.post("http://192.168.49.2:30713/studies/"+"save",studies, {
            headers:{
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    update(studies){
        //return axios.post("http://localhost:8080/studies/"+"update",studies,{
        return axios.post("http://192.168.49.2:30713/studies/"+"update",studies,{
            headers:{
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    list(){
        //return axios.get("http://localhost:8080/studies/"+"list",{
        return axios.get("http://192.168.49.2:30713/studies/"+"list",{
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    listFilter(email){
        //return axios.get("http://localhost:8080/studies/"+"listFilter",{
        return axios.get("http://192.168.49.2:30713/studies/"+"listFilter",{
            params: {email},
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    listByEmail(email){
        //return axios.get("http://localhost:8080/studies/"+"findByEmail",{
        return axios.get("http://192.168.49.2:30713/studies/"+"findByEmail",{
            params: {email},
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }
}
import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { ProfileUserService } from "../../../services/ProfileUserService";
import "../../../styles/ProfileUser.css";
import { FileUpload } from "primereact/fileupload";
import Button from "@mui/material/Button";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("email");

function ProfileUserUpdate() {
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: "white",
            "&:before": {
              borderColor: "white !important",
            },
            "&:after": {
              borderColor: "white !important",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              color: "white",
            },
            "&: focus": {
              color: "white",
            },
            "&:focus:not(.Mui-disabled, .Mui-error):before": {
              color: "white",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#e6f1f7",
            "--TextField-brandBorderHoverColor": "#e6f1f7",
            "--TextField-brandBorderFocusedColor": "#e6f1f7",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
            "&::placeholder": {
              color: "white",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          backgroundColor: "white",
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
          input: {
            "&::placeholder": {
              color: "white",
            },
            color: "white",
          },
        },
      },
      MuiDesktopDatePicker: {
        styleOverrides: {
          root: {
            "& input": {
              color: "white",
            },
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        },
      },
    },
  });

  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    address: "",
    RH: "",
    email: JSON.parse(email),
  });
  const [medicalHistory, setMedicalHistory] = useState(
    new File([], "emptyFile.txt", { type: "text/plain" })
  );

  const [profileResponse, setProfileResponse] = useState([]);
  const profileUserService = new ProfileUserService();
  const fileUploadRef = useRef(null);
  const toast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const findEmail = async () => {
      const profileUserEmail = await FindByEmail();
      setProfileResponse(profileUserEmail);
    };
    findEmail();

    if(token === null){
      navigate("/");
    }

  },[]);

  async function FindByEmail() {
    const profileByEmail = await profileUserService.listByEmail(
      JSON.parse(email)
    );
    return profileByEmail;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const invoiceMedicalHistoryUploadHandler = (event) => {
    toast.current.show({
      severity: "contrast",
      summary: "Operación Exitosa",
      detail: "Archivo cargado",
      className: "toast-custom",
    });
    const [file] = event.files;
    console.log(file);
    setMedicalHistory(file);
    fileUploadRef.current.clear();
  };

  const printFormData = (formData) => {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataProfileUser = new FormData();
    formDataProfileUser.append("id", profileResponse.id)
    formDataProfileUser.append("profileUserDto", JSON.stringify(profile));
    formDataProfileUser.append("medicalHistory", medicalHistory);
    console.log("Request Estudios" + printFormData(formDataProfileUser));
    console.log("ENTRO AL HANLDE SUBMIT");
    const profileUserResponse = await profileUserService.update(
      formDataProfileUser
    );
    console.log(profileUserResponse)
    //window.location.replace('');
    navigate("/home")
  };

  return (
    <form onSubmit={handleSubmit}>
    {console.log("PROFILE RESPONSE", profileResponse)}
    {!profileResponse && navigate("/save_profile_user")}
      <Toast ref={toast} />
      <div className="container-profile-user">
        <h2 className="profile-user">Perfil</h2>
        <Box
          className="box-profile-user"
          height={350}
          width={1200}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.2)",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <ThemeProvider theme={theme}>
            <div id="nameLabelProfileUser">
              <label>Nombre</label>
            </div>

            <TextField
              id="standard-multiline-flexible"
              className="nameProfileUser"
              name="name"
              label=""
              multiline
              variant="outlined"
              maxRows={2}
              defaultValue={profileResponse.name}
              onChange={handleChange}
            />

            <div id="phoneLabelProfileUser">
              <label>Telefono</label>
            </div>

            <TextField
              id="standard-multiline-flexible"
              className="phoneProfileUser"
              name="phone"
              label=""
              multiline
              variant="outlined"
              maxRows={1}
              inputProps={{ maxLength: 10, pattern: "\\d*" }}
              defaultValue={profileResponse.phone}
              onChange={handleChange}
            />

            <div id="addressLabelProfileUser">
              <label>Dirección</label>
            </div>

            <TextField
              id="standard-multiline-flexible"
              className="addressProfileUser"
              name="address"
              label=""
              multiline
              variant="outlined"
              maxRows={2}
              defaultValue={profileResponse.address}
              onChange={handleChange}
            />

            <div id="rhLabelProfileUser">
              <label>RH</label>
            </div>

            <TextField
              id="standard-multiline-flexible"
              className="rhProfileUser"
              name="RH"
              label=""
              multiline
              variant="outlined"
              maxRows={1}
              defaultValue={profileResponse.rh}
              onChange={handleChange}
            />

            <div id="medicalHistoryLabelUser">
              <label>Historia Médica</label>
            </div>
            <FileUpload
              mode="basic"
              ref={fileUploadRef}
              customUpload={true}
              className="medicalHistory"
              name="medicalHistory"
              accept=".pdf"
              maxFileSize={1000000}
              uploadHandler={invoiceMedicalHistoryUploadHandler}
              auto
              chooseLabel="Browse"
            />
          </ThemeProvider>
        </Box>
        <Button className="submitProfileUser" type="submit" variant="contained">
          Guardar
        </Button>
      </div>
    </form>
  );
}

export default ProfileUserUpdate;

import React,{ useState } from 'react';
import '../styles/Register.css'
import { AuthService } from "../services/AuthService";
import { useNavigate } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import { ProfileService } from '../services/ProfileService';

function Register() {
    const [formulario, setFormulario] = useState({
        email: '',
        password: '',
        role: 'client', // Valor predeterminado para la lista desplegable
      });


      const authService = new AuthService();
      const navigate = useNavigate();

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormulario((prevFormulario) => ({
          ...prevFormulario,
          [name]: value,
        }));
      };

      const handleSubmit = async(event) => {
        event.preventDefault();
        try{
          const response = await authService.register(formulario)
          //console.log('Formulario enviado:', formulario);
          //console.log('RESPONSE', response)
          const login = await authService.login(formulario)
          //console.log('Formulario login' , login)
          //console.log('response email',response.email )
          sessionStorage.setItem('token', JSON.stringify(login));
          sessionStorage.setItem('email', JSON.stringify(response.email));
          console.log("ROLE",formulario.role)
          if(formulario.role === 'professional'){
            navigate('/save_profile');
          }else if(formulario.role === 'client'){
            navigate('/save_profile_user');
          }
          
          window.location.replace('');
          //console.log(sessionStorage.getItem('email'))
        }catch(err){
          alert("Error en el Registro")
        }

      };

  return(
    <div className="form-container">
        <br />
        <br />
        <br />
        <br />
      <h2>Formulario de Registro</h2>
      <form onSubmit={handleSubmit}>
        {/* <div className='form-gorup'>
          <input
            type="text"
            name="nombre"
            placeholder='Nombre'
            value={formulario.nombre}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className='form-gorup'>
          <input
            type="text"
            name="apellido"
            placeholder='Apellido'
            value={formulario.apellido}
            onChange={handleChange}
          />
        </div> */}
        <br />
        <div className='form-group-register'>
          <input
            id='emailRegister'
            type="email"
            name="email"
            placeholder='Email'
            value={formulario.email}
            onChange={handleChange}
          />
        </div>
        {/* <div className='form-gorup'>
          <input
            type="tel"
            name="telefono"
            placeholder='Telefono'
            value={formulario.telefono}
            onChange={handleChange}
          />
        </div> */}
        <br />
        <div className='form-group-register'>
          <input
            id='passwordRegister'
            type="password"
            name="password"
            placeholder='Contraseña'
            value={formulario.password}
            onChange={handleChange}
          />
        </div>
        <br />
        <div className='form-group-register'>
          <select
            id='slcTipo'
            name="role"
            value={formulario.role}
            onChange={handleChange}
          > 
            <option value="" disabled selected hidden>Selecciona un tipo de usuario</option>   
            <option value="client">Cliente</option>
            <option value="professional">Profesional</option>
          </select>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />

        <button id='btnRegister' type="submit">Registrarse</button>
      </form>
    </div>
  );
};

export default Register;
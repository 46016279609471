import axios from "axios";

const sessionToken = sessionStorage.getItem("token");
var parseToken = "";
var token = "";
if (sessionToken) {
  parseToken = JSON.parse(sessionToken);
  token = parseToken.token;
  console.log(token);
}

export class OfferService {
  saveOffer(offer) {
    return axios
    //.post("http://localhost:8080/offers/save", offer, {
      .post("http://192.168.49.2:30713/offers/save", offer, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }

  listOffer() {
    return axios
    //.get("http://localhost:8080/offers/list", {
      .get("http://192.168.49.2:30713/offers/list", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }

  findByEmail(email) {
    return axios
    //.get("http://localhost:8080/offers/findByEmail", {
      .get("http://192.168.49.2:30713/offers/findByEmail", {
        params: { email },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }

  findByState(state) {
    //return axios.get("http://localhost:8080/offers/find_state", {
    return axios.get("http://192.168.49.2:30713/offers/find_state", {
      params: { state },
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    }).then((res)=> res.data);
  }

  listByEmailUser(emailUser) {
    return axios
    //.get("http://localhost:8080/offers/findByEmailUser", {
    .get("http://192.168.49.2:30713/offers/findByEmailUser", {
        params: { emailUser },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }

  selectOffer(offer) {
    return axios
    //.post("http://localhost:8080/offers/selectOffer", offer, {
      .post("http://192.168.49.2:30713/offers/selectOffer", offer, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }

  cancelOffer(offer) {
    return axios
    //.post("http://localhost:8080/offers/cancelOffer", offer, {
      .post("http://192.168.49.2:30713/offers/cancelOffer", offer, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }

  successOffer(offer) {
    return axios
    //.post("http://localhost:8080/offers/successOffer", offer, {
      .post("http://192.168.49.2:30713/offers/successOffer", offer, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  }
}

import React, { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { isExpired, decodeToken } from "react-jwt";
import { ProfileService } from "../../../services/ProfileService";
import {
  TextField,
  Box,
  Select,
  MenuItem,
  Alert,
  listClasses,
  Badge,
} from "@mui/material";
import { Avatar } from "primereact/avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router-dom";
import "../../../styles/ViewProfile.css";
import Button from "@mui/material/Button";

const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("email");
var parseToken = "";
var tokenSession = "";

if (token) {
  parseToken = JSON.parse(token);
  tokenSession = parseToken.token;
}

function ViewProfile() {
  const decode = decodeToken(tokenSession);
  console.log("CONSOLE.TOKEN", tokenSession);
  //console.log("DECODE TOKEN",  decode.role);

  const [profileResponse, setProfileResponse] = useState([]);

  const profileService = new ProfileService();
  const navigate = useNavigate();

  useEffect(() => {
    const ListProfile = async () => {
      const profileEmail = await CheckoutProfile();
      setProfileResponse(profileEmail);
    };

    ListProfile();
    if(token === null){
        navigate("/")
    }
  }, []);

  console.log(profileResponse);

  async function CheckoutProfile() {
    const profileByEmail = await profileService.listByEmail(JSON.parse(email));
    return profileByEmail;
  }

  const handleGoBack = () => {
        navigate("/history_offer");
  }

  function getFirstWord(nombre){
    if(!nombre){
      return ''
    }
    const first = nombre.split(' ');
    return first[0]
  }


  return (
    <div className="view-profile-container">
      {!profileResponse && navigate("/save_profile")}
      <CssBaseline />
      {/* <Container className="containerProfile" maxWidth="sm"> */}
      <Box
        className="box-view-profile"
        height={300}
        width={1200}
        sx={{
          bgcolor: "rgba(255, 255, 255, 0.2)",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Avatar
          label={getFirstWord(profileResponse.name)}
          size="xlarge"
          className="p-overlay-badge"
          style={{ backgroundColor: "#4caf4f", color: "#ffffff" }}
        >
          <Badge value="4" />
        </Avatar>
        <h2 className="profile-view">Perfil</h2>

        <div id="labelNameViewProfile">
          <label>Nombre</label>
        </div>
        <div className="nameViewProfile">
          <label>{profileResponse.name}</label>
        </div>

        <div id="labelViewPhone">
          <label>Telefono</label>
        </div>
        <div className="phoneViewProfile">
          <label>{profileResponse.phone}</label>
        </div>

        <div id="labelTypeStudyProfile">
          <label>Profesión</label>
        </div>
        <div className="studyTypeViewProfile">
          <label>{profileResponse.studyType}</label>
        </div>

        <div id="labelScheduleViewProfile">
          <label>Horario Laboral</label>
        </div>
        <div className="scheduleViewProfile">
          <label>
            {profileResponse.beginSchedule} - {profileResponse.endSchedule}
          </label>
        </div>

        <div id="labelZoneViewProfile">
          <label>Zona</label>
        </div>
        <div className="zoneViewProfile">
          <label>{profileResponse.zone}</label>
        </div>

        <div id="labelEmailViewProfile">
          <label>Email</label>
        </div>
        <div className="emailViewProfile">
          <label>{profileResponse.email}</label>
        </div>
      </Box>
      <Button className="submitViewProfile" type="submit" variant="contained" onClick={handleGoBack}>
        Volver
      </Button>
    </div>
  );
}

export default ViewProfile;

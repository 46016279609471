import axios from 'axios';

const sessionToken = sessionStorage.getItem('token')
var parseToken = '';
var token = '';

if (sessionToken){
    parseToken = JSON.parse(sessionToken)
    token = parseToken.token;
    console.log(token)
}

export class ExperienceService{

    save(experience){
        //return axios.post("http://localhost:8080/experience/"+"save",experience,{
        return axios.post("http://192.168.49.2:30713/experience/"+"save",experience,{
            headers:{
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    update(experience){
        //return axios.post("http://localhost:8080/experience/"+"update",experience,{
            return axios.post("http://192.168.49.2:30713/experience/"+"update",experience,{
            headers:{
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }  
        }).then(res=>res.data)
    }

    list(){
        //return axios.get("http://localhost:8080/experience/"+"list",{
        return axios.get("http://192.168.49.2:30713/experience/"+"list",{
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    listFilter(email){
        //return axios.get("http://localhost:8080/experience/"+"listFilter",{
        return axios.get("http://192.168.49.2:30713/experience/"+"listFilter",{
            params: {email},
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    listByEmail(email){
        //return axios.get("http://localhost:8080/experience/"+"findByEmail",{
        return axios.get("http://192.168.49.2:30713/experience/"+"findByEmail",{
            params: {email},
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }
}
import React, { useEffect, useState, useRef } from "react";
import { ExperienceService } from "../../../services/ExperienceService";
import { StudiesService } from "../../../services/StudiesService";
import { useJwt } from "react-jwt";
import CssBaseline from "@mui/material/CssBaseline";
import { TextField, Box, Select, MenuItem, Alert } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "../../../styles/Profile.css";
import { ProfileService } from "../../../services/ProfileService";
import CheckIcon from "@mui/icons-material/Check";
import NumberTextField from "../../shared/NumberTextField";

const token = sessionStorage.getItem("token");
const email = sessionStorage.getItem("email");

function Profile() {
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: "white",
            "&:before": {
              borderColor: "white !important",
            },
            "&:after": {
              borderColor: "white !important",
            },
            "& .MuiSvgIcon-root": {
              color: "white",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              color: "white",
            },
            "&: focus": {
              color: "white",
            },
            "&:focus:not(.Mui-disabled, .Mui-error):before": {
              color: "white",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#e6f1f7",
            "--TextField-brandBorderHoverColor": "#e6f1f7",
            "--TextField-brandBorderFocusedColor": "#e6f1f7",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
            "&::placeholder": {
              color: "white",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          backgroundColor: "white",
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
          input: {
            "&::placeholder": {
              color: "white",
            },
            color: "white",
          },
        },
      },
      MuiDesktopDatePicker: {
        styleOverrides: {
          root: {
            "& input": {
              color: "white",
            },
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        },
      },
    },
  });

  const { decodeToken, isExpired } = useJwt(token);
  console.log(decodeToken);

  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    study_type: "",
    beginSchedule: "",
    endSchedule: "",
    zone: "",
    email: JSON.parse(email),
  });

  const [experiences, setExperiences] = useState({
    name: "",
    date: React.useState(dayjs("2022-04-17")),
    type: "",
    email: JSON.parse(email),
  });

  const [studies, setStudies] = useState({
    name: "",
    date: React.useState(dayjs("2022-04-17")),
    type: "",
    email: JSON.parse(email),
  });

  const [diploma, setDiploma] = useState(null);
  const [rethus, setRethus] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const fileUploadRef = useRef(null);

  const profileService = new ProfileService();
  const studiesService = new StudiesService();
  const experienceService = new ExperienceService();
  const [response, setResponse] = useState(null);

  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const profileEmail = await CheckoutProfile();
      setResponse(profileEmail);
    };
    fetchData();
    console.log(response);
    if (response !== null) {
      navigate("/");
      console.log("Entro al if use effect");
    }
  }, []);

  async function CheckoutProfile() {
    const profileByEmail = await profileService.listByEmail(JSON.parse(email));
    console.log("checkout" + profileByEmail);
    return profileByEmail;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("handle");
    console.log("name" + name);
    console.log("value" + value);
    setProfile((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (newDateTime) => {
    const newDateString = newDateTime.toISOString();
    console.log(newDateString);
    const dateOnly = newDateString.substring(0, 10);
    console.log(dateOnly);

    setStudies((prevOffer) => ({
      ...prevOffer,
      date: dateOnly,
    }));
    console.log(studies.date);
  };

  const handleDateExperienceChange = (newDateTime) => {
    const newDateString = newDateTime.toISOString();
    console.log(newDateString);
    const dateOnly = newDateString.substring(0, 10);
    console.log(dateOnly);

    setExperiences((prevExperience) => ({
      ...prevExperience,
      date: dateOnly,
    }));
  };

  const handleExperienciaChange = (e) => {
    const { name, value } = e.target;

    setExperiences((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStudiesChange = (e) => {
    const { name, value } = e.target;
    setStudies((prevStudies) => ({
      ...prevStudies,
      [name]: value,
    }));
  };

  const invoiceDiplomaUploadHandler = (event) => {
    toast.current.show({
      severity: "contrast",
      summary: "Operación Exitosa",
      detail: "Archivo cargado",
      className: "toast-custom",
    });
    const [file] = event.files;
    setDiploma(file);
    fileUploadRef.current.clear();
  };

  const invoiceRethusUploadHandler = (event) => {
    toast.current.show({
      severity: "contrast",
      summary: "Operación Exitosa",
      detail: "Archivo cargado",
      className: "toast-custom",
    });
    const [file] = event.files;
    setRethus(file);
    fileUploadRef.current.clear();
  };

  const invoiceResolutionUploadHandler = (event) => {
    toast.current.show({
      severity: "contrast",
      summary: "Operación Exitosa",
      detail: "Archivo cargado",
      className: "toast-custom",
    });
    const [file] = event.files;
    setResolution(file);
    fileUploadRef.current.clear();
  };

  const invoiceCertificateUploadHandler = (event) => {
    toast.current.show({
      severity: "contrast",
      summary: "Operación Exitosa",
      detail: "Archivo cargado",
      className: "toast-custom",
    });
    const [file] = event.files;
    setCertificate(file);
    fileUploadRef.current.clear();
  };

  const printFormData = (formData) => {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
  };

  const handleAddExperiences = (experiences) => {
    console.log("Experieincia Handle" + experiences);
    setProfile((prevProfile) => ({
      ...prevProfile,
      experiences: [experiences],
    }));
  };

  const handleAddStudies = async (studies) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      studies: [studies],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("entro al envio");
    const formDataStudies = new FormData();
    formDataStudies.append("studiesResponse", JSON.stringify(studies));
    formDataStudies.append("diploma", diploma);
    formDataStudies.append("rethus", rethus);
    formDataStudies.append("resolution", resolution);
    console.log("Request Estudios" + printFormData(formDataStudies));
    const responseStudies = await studiesService.save(formDataStudies);
    console.log("EStudios" + responseStudies);

    const formDataExperiences = new FormData();
    formDataExperiences.append("experience", JSON.stringify(experiences));
    formDataExperiences.append("certificate", certificate);
    printFormData(formDataExperiences);
    const responseExperience = await experienceService.save(
      formDataExperiences
    );
    console.log("Experiencia" + responseExperience);

    const responseProfile = profileService.save(profile);
    console.log(responseProfile);
    navigate("/history_offer");
    // Validar y procesar los datos antes de agregarlos a la tabla
    //if (profile.number && profile.name && profile.phone) {
    //setExperiences((prevData) => [...prevData, profile]);
    //}
  };

  return (
    <form onSubmit={handleSubmit}>
      {console.log(response && navigate("/"))}
      {/* {response.map((item,index) =>{
          console.log(item)
          console.log(index)
        })} */}
      <Toast ref={toast} />
      <div className="profile-container">
        <h2 className="profile">Perfil</h2>
        <CssBaseline />
        {/* <Container className="containerProfile" maxWidth="sm"> */}
        <Box
          className="box-profile"
          height={300}
          width={1200}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.2)",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <ThemeProvider theme={theme}>
            <div id="nameLabelProfile">
              <label>Nombre</label>
            </div>
            <TextField
              id="standard-multiline-flexible"
              className="nameProfile"
              name="name"
              label=""
              multiline
              variant="outlined"
              maxRows={4}
              onChange={handleChange}
            />
            <div id="phoneLabelProfile">
              <label>Numero</label>
            </div>
            <TextField
              id="standard-multiline-flexible"
              className="numberProfile"
              name="phone"
              label=""
              multiline
              variant="outlined"
              inputProps={{ maxLength: 10, pattern: "\\d*" }}
              //validator={/^\d{10}$/}
              onChange={handleChange}
            />
            <div id="studyTypeLabel">
              <label>Profesion</label>
            </div>
            <Select
              defaultValue="Auxiliar de Enfermeria"
              id="named-select"
              className="studyTypeSelect"
              name="study_type"
              onChange={handleChange}
            >
              <MenuItem value="AUX">Auxiliar de Enfermeria</MenuItem>
              <MenuItem value="ENF">Enfermeria</MenuItem>
            </Select>
            <div id="workingHourLabel">
              <label>Horario Laboral</label>
            </div>
            <Select
              defaultValue="Lunes"
              id="name-select"
              className="workingHourSelect"
              name="beginSchedule"
              onChange={handleChange}
            >
              <MenuItem value="Lun">Lunes</MenuItem>
              <MenuItem value="Mar">Martes</MenuItem>
              <MenuItem value="Mie">Miercoles</MenuItem>
              <MenuItem value="Jue">Jueves</MenuItem>
              <MenuItem value="Vie">Viernes</MenuItem>
              <MenuItem value="Sab">Sabado</MenuItem>
              <MenuItem value="Dom">Domingo</MenuItem>
            </Select>
            <div id="separatorLabel">
              <h2>-</h2>
            </div>
            <Select
              defaultValue="Lunes"
              id="name-select"
              className="workingHourSelectTwo"
              name="endSchedule"
              onChange={handleChange}
            >
              <MenuItem value="Lun">Lunes</MenuItem>
              <MenuItem value="Mar">Martes</MenuItem>
              <MenuItem value="Mie">Miercoles</MenuItem>
              <MenuItem value="Jue">Jueves</MenuItem>
              <MenuItem value="Vie">Viernes</MenuItem>
              <MenuItem value="Sab">Sabado</MenuItem>
              <MenuItem value="Dom">Domingo</MenuItem>
            </Select>
            <div id="zoneLabel">
              <label>Zona de Trabajo</label>
            </div>
            <Select
              defaultValue="Lunes"
              id="name-select"
              className="zoneProfile"
              name="zone"
              onChange={handleChange}
            >
              <MenuItem value="OCC">Occidente</MenuItem>
              <MenuItem value="ORI">Oriente</MenuItem>
              <MenuItem value="NOR">Norte</MenuItem>
              <MenuItem value="SUR">Sur</MenuItem>
              <MenuItem value="SUROR">Suroriente</MenuItem>
              <MenuItem value="SUROC">Suroccidente</MenuItem>
              <MenuItem value="NOROC">Noroccidente</MenuItem>
              <MenuItem value="NOROR">Nororiente</MenuItem>
              <MenuItem value="NODEF">No Definido</MenuItem>
            </Select>
          </ThemeProvider>
        </Box>
        {/* </Container> */}
        <h2 className="studies">Estudios</h2>
        <Box
          className="box-studies"
          height={300}
          width={1200}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.2)",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <ThemeProvider theme={theme}>
            <div id="nameLabelStudies">
              <label>Nombre Institución</label>
            </div>
            <TextField
              id="standard-multiline-flexible"
              className="nameStudies"
              name="name"
              label=""
              multiline
              variant="outlined"
              maxRows={4}
              onChange={handleStudiesChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="labelDateGraduation">
                <label>Fecha de Graduación</label>
              </div>
              <DesktopDatePicker
                className="dateGraduation"
                format="DD-MM-YYYY"
                //minDate={dayjs().startOf('day')}
                defaultValue={dayjs()}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
            <div id="diplomaLabel">
              <label>Diploma y Acta de Grado</label>
            </div>
            <FileUpload
              mode="basic"
              ref={fileUploadRef}
              customUpload={true}
              className="diplomaFile"
              name="diploma"
              accept=".pdf"
              maxFileSize={1000000}
              uploadHandler={invoiceDiplomaUploadHandler}
              auto
              chooseLabel="Browse"
            />
            <div id="rethusLabel">
              <label>Rehus</label>
            </div>
            <FileUpload
              mode="basic"
              ref={fileUploadRef}
              customUpload={true}
              className="rethusFile"
              name="rethus"
              accept=".pdf"
              maxFileSize={1000000}
              uploadHandler={invoiceRethusUploadHandler}
              auto
              chooseLabel="Browse"
            />
            <div id="resolutionLabel">
              <label>Resolución</label>
            </div>
            <FileUpload
              mode="basic"
              ref={fileUploadRef}
              customUpload={true}
              className="resolutionFile"
              name="resolution"
              accept=".pdf"
              maxFileSize={1000000}
              uploadHandler={invoiceResolutionUploadHandler}
              auto
              chooseLabel="Browse"
            />
          </ThemeProvider>
        </Box>

        <h2 className="experience">Experiencia</h2>
        <Box
          className="box-experience"
          height={180}
          width={1200}
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.2)",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <ThemeProvider theme={theme}>
            <div id="nameExperienceLabel">
              <label>Nombre Última Experiencia</label>
            </div>
            <TextField
              id="standard-multiline-flexible"
              className="nameExperience"
              name="name"
              label=""
              multiline
              variant="outlined"
              maxRows={4}
              onChange={handleExperienciaChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="dateExperienceLabel">
                <label>Fecha de Retiro</label>
              </div>
              <DesktopDatePicker
                className="dateExperience"
                format="DD-MM-YYYY"
                //minDate={dayjs().startOf('day')}
                defaultValue={dayjs()}
                onChange={handleDateExperienceChange}
              />
            </LocalizationProvider>
            <div id="certificateLabel">
              <label>Certificado</label>
            </div>
            <FileUpload
              mode="basic"
              ref={fileUploadRef}
              customUpload={true}
              className="certificateFile"
              name="data"
              accept=".pdf"
              maxFileSize={1000000}
              uploadHandler={invoiceCertificateUploadHandler}
              auto
              chooseLabel="Browse"
            />
          </ThemeProvider>
        </Box>
        <Button className="submitProfile" type="submit" variant="contained">
          Guardar
        </Button>
      </div>
    </form>
  );
}

export default Profile;

import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../src/styles/Home.css";

function Home() {
  const navigate = useNavigate();

  const handleInicioSesion = () => {
    navigate("/register");
  };

  return (
    <div className="container-home">
      <Link className="image-home">
        <img src="./equipo-profesionales.png" width="500" />
      </Link>
      <div className="title-div">
        <h1 style={{ fontWeight: "bold" }}>
          Transforma la manera en que accedes a servicios de enfermería
        </h1>
        {/* <h3 style={{fontWeight: 'bold'}}>
          Una plataforma integral para contratar y ofrecer servicios de
          enfermería adaptados a tus necesidades.
        </h3> */}
      </div>
      <div className="body-home">
        <h4 style={{ fontSize: "120%", opacity: "0.8" }}>
          Nuestra plataforma te permite encontrar y ofrecer servicios de
          enfermería de manera personalizada y conveniente. Simplificamos el
          proceso de contratación y ofrecimiento de servicios, conectando a
          profesionales de enfermería con aquellos que necesitan cuidados de
          calidad
        </h4>
      </div>
      <Button
        className="submitHome"
        variant="contained"
        onClick={handleInicioSesion}
        style={{
          backgroundColor: "#FF5722",
          color: "#FFFFFF",
          borderRadius: "25px",
          padding: "10px 20px",
          fontSize: "16px",
          boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
          transition: "background-color 0.3s ease",
        }}
      >
        Registrate
      </Button>
    </div>
  );
}

export default Home;

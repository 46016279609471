import React, {useState} from "react";
import { TextField } from "@mui/material";

const NumberTextField = (props, setProfile) => {
    const { validator, ...otherProps } = props;
    const [error, setError] = useState(false)

    const handleChange = (event) =>{
        const { value } = event.target;
        console.log(validator)
        if(validator){
            const isValid = validator.test(value)
            setError(!isValid)
        }
    };

    return(
        <TextField 
            onError={error}
            //helperText={error ? "Invalid input" : ""}
            {...otherProps}
            inputProps={{ maxLength: 10, pattern: '\\d*' }}
            onChange={handleChange}
            setProfile={setProfile}   
        />
    )
}

export default NumberTextField;
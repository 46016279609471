import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  Redirect
} from "react-router-dom";
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Navbar from './layout/navbar';
import Profile from './components/profile/professional/SaveProfile';
import SaveOffer from './components/offer/SaveOffer';
import SaveSupport from './components/support/SaveSupport';
import HistoryOffer from './components/offer/HistoryOffer';
import DashboardOffer from './components/offer/DashboardOffer';
import UpdateProfile from './components/profile//professional/UpdateProfile';
import ViewProfile from './components/profile/professional/ViewProfile';
import ProfileUser from './components/profile/user/SaveProfileUser';
import ViewProfileUser from './components/profile/user/ViewProfileUser';
import ProfileUserUpdate from './components/profile/user/UpdateProfileUser';
import SaveSupportHome from './components/support/SaveSupportHome';

function App() {
  return (
    <Router>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Register />} />
          <Route path="/save_profile" element={<Profile />} />
          <Route path='/update_profile' element={<UpdateProfile />}/>
          <Route path='/save_offer' element={<SaveOffer />} />
          <Route path='/save_support' element={<SaveSupport />} />
          <Route path='/contact' element={<SaveSupportHome />} />
          <Route path='/history_offer' element={<HistoryOffer />} />
          <Route path="/home" element={<DashboardOffer />}/>
          <Route path='/view_profile' element={<ViewProfile />} />
          <Route path='/save_profile_user' element={<ProfileUser />} />
          <Route path='/view_profile_user' element={<ViewProfileUser />}/>
          <Route path='/update_profile_user' element={<ProfileUserUpdate />} /> 
        </Routes>
    </Router>
  );
}

export default App;

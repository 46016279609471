import { user } from "@nextui-org/react";
import axios from "axios";

const sessionToken =  sessionStorage.getItem('token')
var parseToken = '';
var token = '';

if(sessionToken){
    parseToken = JSON.parse(sessionToken)
    token = parseToken.token;
}

export class ProfileUserService{

    save(userProfile){
        //axios.post("http://localhost:8080/profile/user/save",userProfile,{
        axios.post("http://192.168.49.2:30713/profile/user/save",userProfile,{
            headers:{
                'Content-Type':',multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    update(userProfile){
        //axios.post("http://localhost:8080/profile/user/update",userProfile,{
        axios.post("http://192.168.49.2:30713/profile/user/update",userProfile,{
            headers:{
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    list(){
        //axios.get("http://localhost:8080/profile/user/list",{
        axios.get("http://192.168.49.2:30713/profile/user/list",{
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

    listByEmail(email){
        //return axios.get("http://localhost:8080/profile/user/listEmail",{
        return axios.get("http://192.168.49.2:30713/profile/user/listEmail",{
            params:{email},
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(res=>res.data)
    }

}